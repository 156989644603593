import React, { useState } from "react";

const DynamicTagsInput = () => {
  const [tags, setTags] = useState([""]); // Start with one empty input

  const addTag = () => {
    setTags([...tags, ""]);
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    const newTags = tags.slice();
    newTags[index] = event.target.value;
    setTags(newTags);
  };

  return (
    <div className="form-group">
      <label htmlFor="tags">Tags:</label>
      <div className="tags-input">
        {tags.map((tag, index) => (
          <div key={index} className="tag-item">
            <input
              type="text"
              name={`tag-${index}`}
              value={tag}
              onChange={(e) => handleInputChange(index, e)}
              className="tag-input"
            />
            <button
              type="button"
              onClick={() => removeTag(index)}
              className="remove-tag-btn"
            >
              &times;
            </button>
          </div>
        ))}
        <div className="container">
            <button type="button" onClick={addTag} className="add-tag-btn">
            +
            </button>
        </div>
      </div>
    </div>
  );
};

export default DynamicTagsInput;