import './App.css';
import { useState } from 'react';
import Popup from './Popup';

const TableRow = ({ row, isExpanded, toggleExpand, openPopup }) => (
  <>
    <tr>
      <td onClick={() => toggleExpand(row.id)}>{row.id}</td>
      <td onClick={() => toggleExpand(row.id)}>{row.company}</td>
      <td onClick={() => toggleExpand(row.id)}>{row.position}</td>
      <td onClick={() => toggleExpand(row.id)}>{row.status}</td>
      <td onClick={() => openPopup("Edit")}>✏️</td>
    </tr>
    {isExpanded && (
      <tr>
        <td colSpan="5">
          <div>
            <p>Job Description: {row.description}</p>
            <p>Location: {row.location}</p>
            <p>Tags: {row.tags}</p>
          </div>
        </td>
      </tr>
    )}
  </>
);

function App() {

  const [expandedRows, setExpandedRows] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [state, setState] = useState([]);

  const openPopup = (s) => {
    setState(s);
    setIsPopupOpen(true);
  };

  const data = [
    { id: 1, company: 'Google', position: "Software Engineer", status: "In Progress", description:"Software engineering job at google, repsonsibilities include x, y, x.", tags:"#Remote"},
    { id: 2, company: 'Apple', position: "Software Engineer", status: "In Progress", description:"Software engineering job at google, repsonsibilities include x, y, x.", tags:"#Remote" },
    // Add more rows as needed
  ];

  const toggleExpand = (rowId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(rowId)
        ? prevExpandedRows.filter(id => id !== rowId)
        : [...prevExpandedRows, rowId]
    );
  };

  return (
    <div className="App">
      {isPopupOpen && (
        Popup(setIsPopupOpen, state)
      )}
      {!isPopupOpen && (
      <div className="App-header">
        <div className='add-button-container'>
          <button className='add-button' onClick={() => openPopup("Add")}>+ Add Job</button>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{width:"5%"}}>#</th>
              <th>Company</th>
              <th>Position</th>
              <th style={{width:"10%"}}>Status</th>
              <th style={{width:"2%"}}></th>
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <TableRow
                key={row.id}
                row={row}
                isExpanded={expandedRows.includes(row.id)}
                toggleExpand={toggleExpand}
                openPopup={openPopup}
              />
            ))}
          </tbody>
        </table>
      </div>
      )}
    </div>
    
  );
}

export default App;