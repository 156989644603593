import DynamicTagsInput from "./Tags"

const Popup = (setIsPopupOpen, state) => {
  
    const closePopup = () => {
      setIsPopupOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted');
        closePopup();
    };

    return (
        <div className="modal">
            <div className="modal-content">
            <div className='header-container'>
                <span className='form-title'>
                {state} Entry
                </span>
                <span className="close" onClick={closePopup}>
                &times;
                </span>
            </div>
            <form onSubmit={handleSubmit} className="aligned-form">
                <div className="form-group">
                <label htmlFor="company">Company:</label>
                <input type="text" name="company" id="company" />
                </div>
                <div className="form-group">
                <label htmlFor="position">Position:</label>
                <input type="text" name="position" id="position" />
                </div>
                <div className="form-group">
                <label htmlFor="status">Status:</label>
                <select name="status" id="status">
                    <option value="submitted">Submitted</option>
                    <option value="interviewed">Interviewed</option>
                    <option value="accepted">Accepted</option>
                    <option value="declined">Declined</option>
                </select>
                </div>
                <div className="form-group">
                <label htmlFor="description">Description:</label>
                <textarea name="description" id="description"></textarea>
                </div>
                <div className="form-group">
                <label htmlFor="location">Location:</label>
                <input type="text" name="location" id="location" />
                </div>
                <DynamicTagsInput/>
                <div className='submit-container'>
                    <span>
                        <button className='submit' type="submit">Submit</button>
                        {state === "Edit" &&
                        <button className='delete'>Delete</button>
                        }
                    </span>
                </div>
            </form>
            
            </div>
        </div>
    );
};

export default Popup;